<!-- 用户换电统计 -->
<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="10">
        <eb-date-range v-model="dateRange" @change="changeDate"></eb-date-range>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="initData()">查询</el-button>
        <el-button type="primary" @click="doExportAvgUserUse()"  v-auth="this.per.EXPORT_AVG_EXCHANGE"
          >导出换电数据</el-button
        >
      </el-col>
    </el-row>

    <div>
      总计
      <span class="text-primary text-bd"> {{ dataSource.length }} </span> 名用户
      ，每天平均换电
      <span class="text-primary text-bd"> {{ countAvg }} </span> 次
    </div>

    <el-table
      border
      height="650"
      :data="duplicate"
      class="page-top-space"
      row-class-name="table-row"
      @sort-change="sortChange"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="姓名" prop="realName"></el-table-column>
      <el-table-column label="电话" prop="userName"></el-table-column>
      <el-table-column
        label="总换电次数"
        prop="iTotal"
        sortable="custom"
      ></el-table-column>

      <el-table-column
        label="平均换电次数"
        prop="averageNum"
        sortable="custom"
      ></el-table-column>
    </el-table>

    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="pages.total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
import { fetchUserAvgExchange, exportAvgUserUse } from "@/api/statistics";
import moment from "moment";

export default {
  data() {
    let startTime = moment()
      .subtract(1, "month")
      .startOf("d")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("d").format("YYYY-MM-DD HH:mm:ss");
    return {
      pages: {
        size: 50,
        pageIndex: 1,
        total: 0,
      },
      dataSortDupli: [],
      duplicate: [],
      dateRange: [startTime, endTime],
      dataSource: [],
      countAvg: 0,
      query: {
        startTime: startTime,
        endTime: endTime,
        userId: -1,
      },
    };
  },

  mounted() {
    this.initData();
  },

  methods: {
    changePage(numb) {
      this.duplicate = this.dataSource
        .concat()
        .splice(
          (numb - 1) * this.pages.size,
          numb * this.pages.size
        );
      this.pages.pageIndex = numb;
    },

    doExportAvgUserUse() {
      exportAvgUserUse({
        startTime: this.query.startTime,
        endTime: this.query.endTime,
      }).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `${this.query.startTime}-${this.query.endTime}换电次数统计.xlsx`
        );
      });
    },

    sortChange(e) {
      this.dataSource.sort((a, b) => {
        if (e.order === "ascending") {
          return a[e.prop] - b[e.prop];
        } else if (e.order === "descending") {
          return b[e.prop] - a[e.prop];
        }
      });

      if (e.order !== "descending" && e.order !== "ascending") {
        this.dataSource = this.dataSortDupli.concat();
      }

      this.changePage(1);
    },

    changeDate(e, b) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0])
          .startOf("d")
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1])
          .endOf("d")
          .format("YYYY-MM-DD HH:mm:ss");
      }
      this.initData();
    },

    initData() {
      fetchUserAvgExchange(this.query).then((res) => {
        this.dataSource = res.data.data.records || [];
        this.dataSortDupli = this.dataSource.concat();
        this.pages.total = this.dataSource.length;
        this.changePage(1);
        let countAvg = 0;
        this.dataSource.map((item) => {
          countAvg += item.averageNum;
        });
        this.countAvg = (countAvg / this.dataSource.length).toFixed(2);
      });
    },
  },
};
</script>
